import { call, put, takeLatest } from "redux-saga/effects";
import api from "../../../config/api";
import { sendOTPPending, sendOTPSuccess, sendOTPFail } from "../slice/otpSlice";
import { OTPFormData } from "../models/models";
import { PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";

const Login_API_URL = "profile/otp/submit";
//const Login_API_URL = "./json/submitOTP.json";

// const data ={
//     "otpRef": "ROP-103940",
//     "otpKey":9590
//    }

const sendLoginOTPData = async (otpData: OTPFormData) => {
  const data = { otpRef: otpData.otpRef, otpKey: otpData.otpKey };
  const headerData = {
    headers: {
      accessToken: `${sessionStorage.getItem("temptoken")}`,
      "content-type": "application/json",
      "Access-Control-Expose-Headers": "Authorization",
    },
  };
  const response = await api.post(Login_API_URL, data, headerData);
  //const response = await axios.get(Login_API_URL);

  return response;
};

function* sendOTPData(
  action: PayloadAction<OTPFormData>
): Generator<any, void, any> {
  const { payload } = action;
  try {
    const response = yield call(sendLoginOTPData, payload);
    sessionStorage.setItem("accesstoken", response?.headers?.authorization);
    yield put(sendOTPSuccess(response.data));
  } catch (error: any) {
    yield put(sendOTPFail(error));
  }
}

export function* watchSendOTPData(): any {
  yield takeLatest(sendOTPPending.type, sendOTPData);
}
