import { call, put, takeEvery } from 'redux-saga/effects';
import { getPromotionDataRequest, getPromotionDataFail, getPromotionDataSuccess } from '../../slice/promotionSlice';
// import api from '../../config/api';
// import {config} from '../../config/global';
import { PayloadAction } from '@reduxjs/toolkit';
import { PromotionDataPayload } from '../../models/models';
import axios from "axios";

const data = {
    mode: "no-cors",
    headers: {
        "content-type": "application/json",
        "Access-Control-Allow-Origin": "*",
    },
    body: {},
};

const currentCountry = localStorage.getItem('country') || 'th';
const currentLanguage =  localStorage.getItem('i18nextLng') || 'en';

const fetchPromotionData = async (promotionDataPayload: PromotionDataPayload) => {
    const path = `/assets/${currentCountry}/${currentLanguage}/promotion.json`;
    // const PROMOTION_URL = config.TEAMSITE_PROMOTION_LIST + '/' + promotionDataPayload.currentLanguage + '/' + promotionDataPayload.currentCountry;
    const response = await axios.get(path, data);
    return response;
};

function* fetchPromotionSaga(action: PayloadAction<PromotionDataPayload>): Generator<any, void, any> {
    const {payload } = action;
    try {
        const response = yield call(fetchPromotionData, payload);

        yield put(getPromotionDataSuccess(response.data));
    } catch (error: any) {
        yield put(getPromotionDataFail(error.message));
    }
}

export function* watchFetchPromotionData() {
    yield takeEvery(getPromotionDataRequest.type, fetchPromotionSaga);
}


