import { call, put, takeEvery } from "redux-saga/effects";
import {
  getHomeTertiaryCarouselDataFail,
  getHomeTertiaryCarouselDataRequest,
  getHomeTertiaryCarouselDataSuccess,
} from "../slice/tertiaryCarouselSlice";
// import api from '../../../config/api';
import axios from "axios";

const API_URL = "./json/specialOffersData.json";

function* fetchTertiaryCarouselSaga(): any {
  try {
    const response = yield call(axios.get, API_URL, { baseURL: "/" });
    yield put(getHomeTertiaryCarouselDataSuccess(response.data));
  } catch (error: any) {
    yield put(getHomeTertiaryCarouselDataFail(error.message));
  }
}

export function* watchFetchTertiaryCarouselData() {
  yield takeEvery(
    getHomeTertiaryCarouselDataRequest.type,
    fetchTertiaryCarouselSaga
  );
}
