import { call, put, takeLatest } from "redux-saga/effects";
import api from "../../../config/api";
import {
  getLoginSectionDataPending,
  getLoginSectionDataSuccess,
  getLoginSectionDataFailure,
} from "../slice/loginSlice";
import axios from "axios";
import { ROPLoginFormData } from "../models/models";
import { PayloadAction } from "@reduxjs/toolkit";
import { tr } from "date-fns/locale";

const Login_API_URL = "profile/login";

const fetchLoginData = async (loginPayload: ROPLoginFormData) => {
  let data = {
    mode: "no-cors",
    headers: {
      "content-type": "application/json",
      "Access-Control-Expose-Headers": "accessToken",
    },
    loader: true,
  };
  let loginPayloadData = {
    memberId: loginPayload.memberId,
    password: loginPayload.password,
  };

  const response = await api.post(Login_API_URL, loginPayloadData, data);
  console.log("response", response);
  sessionStorage.setItem("temptoken", response.headers.accesstoken);
  return response;
};

function* getLoginData(
  action: PayloadAction<ROPLoginFormData>
): Generator<any, void, any> {
  const { payload } = action;
  try {
    const response = yield call(fetchLoginData, payload);

    yield put(getLoginSectionDataSuccess(response.data));
  } catch (error: any) {
    yield put(getLoginSectionDataFailure(error));
  }
}

export function* watchLoginData(): any {
  yield takeLatest(getLoginSectionDataPending.type, getLoginData);
}
