import { call, put, takeEvery } from "redux-saga/effects";
import {
  getHomePageDataFail,
  getHomePageDataPending,
  getHomePageDataSuccess,
} from "../slice/homeSlice";
import api from "../../../config/api";

const API_URL = "/data.json";

function* fetchHomeSaga(): any {
  try {
    const response = yield call(api.get, API_URL, { baseURL: "/" });
    yield put(getHomePageDataSuccess(response.data));
  } catch (error: any) {
    yield put(getHomePageDataFail(error.message));
  }
}

export function* watchFetchHomeData() {
  yield takeEvery(getHomePageDataPending.type, fetchHomeSaga);
}
