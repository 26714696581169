import { call, put, takeEvery } from 'redux-saga/effects';
import { getPrivilegeDataRequest, getPrivilegeDataFail, getPrivilegeDataSuccess } from '../../slice/privilegeSlice';
import {config} from '../../../../config/global';
import { PayloadAction } from '@reduxjs/toolkit';
import { QueryPayload } from '../../models/models';
// import api from '../../config/api';
import axios from "axios";


const data = {
    mode: "no-cors",
    headers: {
        "content-type": "application/json",
        "Access-Control-Allow-Origin": "*",
    },
    body: {},
};

// const currentCountry = localStorage.getItem('country') || 'th';
// const currentLanguage =  localStorage.getItem('i18nextLng') || 'en';

const fetchPrivilegeData = async (privilegeDataPayload: QueryPayload) => {
    console.log(privilegeDataPayload.currentLanguage, privilegeDataPayload.currentCountry)
    // const path = `./${currentCountry}/${currentLanguage}/privilege.json`;
    const PRIVILEGE_URL = config.TEAMSITE_PRIVILEGE_LIST;
    const response = await axios.get(PRIVILEGE_URL, data);    
    return response;
};

function* fetchPrivilegeSaga(action: PayloadAction<QueryPayload>): Generator<any, void, any> {
    const {payload } = action;
    try {
        const response = yield call(fetchPrivilegeData, payload);

        yield put(getPrivilegeDataSuccess(response.data));
    } catch (error: any) {
        yield put(getPrivilegeDataFail(error.message));
    }
}

export function* watchFetchPrivilegeData() {
    yield takeEvery(getPrivilegeDataRequest.type, fetchPrivilegeSaga);
}


